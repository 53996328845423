<template>
  <v-snackbar :timeout="6000" bottom color="red darken-2" v-model="errorStr" class="white--text" v-if="error">
    {{error}}
  </v-snackbar>
</template>

<script>
export default {
  props: ["error"],
  data() {
    return {
      errorStr: this.error
    };
  },
  watch: {
    error(val) {
      this.errorStr = val;
    }
  },
  methods:{
    
  }
};

</script>

<style>

</style>
