<template>
  <v-container fluid>
    <v-card raised>
      <v-card-title class="bg-clr">
        <v-btn fab flat @click.native="goback">
          <v-icon class="pointer" v-ripple>arrow_back</v-icon>
        </v-btn>
        <span class="headline">Edit Additional Charges</span>
      </v-card-title>
      <v-card-text class="content-border">
        <v-form ref="form">
          <div class="heading primary-clr">Countries</div>
          <v-layout row>
            <v-flex md5>
              <span class="muted caption">Select Working Countries</span>
              <v-select
                :items="workingCountries"
                placeholder="Select Working Countries"
                class="pt-0"
                v-model="countrySortName"
                :rules="[rules.required]"
                single-line
              />
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr">Location Details</div>
          <v-layout row>
            <v-flex md5>
              <span class="muted caption">Source Country</span>
              <v-autocomplete
                :items="countries"
                placeholder="Source Country"
                item-text="country_name"
                class="pt-0"
                v-model="source"
                autocomplete="offfadsf"
                :rules="[rules.required]"
                single-line
              />
            </v-flex>
            <v-flex md5 class="addMilestone">
              <!-- <v-text-field
                v-model="dropoff"
                prepend-icon="place"
                :id="`autocomplete1`"
                autocomplete="offfadsf"
                label="Destination Location"
                placeholder="Destination Location"
                :rules="[rules.required]"
              ></v-text-field> -->
              <span class="muted caption">Destination Country</span>
              <v-autocomplete
                placeholder="Destination Country"
                :items="countries"
                item-text="country_name"
                class="pt-0"
                :disabled="readOnly"
                v-model="dropoff"
                autocomplete="offfadsf"
                :rules="[rules.required]"
              />
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />
          <div class="heading primary-clr">Additional Charges</div>

          <!-- <v-combobox
            v-model="select"
            :items="items"
            item-text="name"
            item-value="value"
            label="Add additional charges name"
            multiple
            slot
            deletable-slot
            :rules="[rules.required]"
          ></v-combobox> -->
          <v-layout row>
            <v-flex md5>
              <v-icon
                color="orange darken-1"
                class="white--text add-icon"
                flat
                @click.native="addChecklist()"
                >add</v-icon
              >
              <v-text-field
                :key="i"
                v-for="(checklist, i) in checklistField"
                v-model="checklist.name"
                placeholder="Enter Additional Charge Name"
                class="text-field-check"
                :rules="[rules.required, rules.noWhiteSpace]"
                append-icon="close"
                @click:append="openDeletePopup(i, checklist)"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <hr class="divider-rule" />

          <v-layout row>
            <v-btn
              color="orange darken-1"
              style="color: white"
              @click.prevent="OpenConfirmPop()"
              :loading="loading"
              >Submit</v-btn
            >
          </v-layout>
        </v-form>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
        <success-dialog
          :content="x.message"
          :show="x.success"
          :onclose="closeMessage"
        />
        <v-dialog v-model="dialog" persistent max-width="600">
          <v-form ref="form">
            <v-card>
              <v-card-title
                class="title-layout-size"
                dark
                style="background-color: #fee9d0; color: black"
                flat
              >
                <span class="headline">Delete Additional Charge</span>
                <v-spacer></v-spacer>
                <v-btn icon @click.prevent="close()">
                  <v-icon class="heading grey--text text--darken-4"
                    >close</v-icon
                  >
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex md12>{{ message }} </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  @click="deleteRequest()"
                  :disabled="loading"
                >
                  Delete
                </v-btn>
                <v-btn color="black darken-1" flat @click="close()"
                  >Cancel</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-form>
        </v-dialog>
        <v-dialog
          v-model="dialog2"
          :max-width="options.width"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar dark :color="options.color" dense flat>
              <v-toolbar-title class="white--text"
                >Edit Additional Charge</v-toolbar-title
              >
            </v-toolbar>
            <v-card-text class="pa-4"
              >Are you sure want to edit the additional charge ?</v-card-text
            >
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click.prevent="checkAdd()"
                >Yes</v-btn
              >
              <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { StorageKeys, countries } from "../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { editAdditionalCharge } from "../../constants/api-urls";
import { select } from "@syncfusion/ej2-base";
export default {
  created() {
    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete("1");
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);
    this.fetchAdditionalChargeList();
  },
  data() {
    return {
      document: [],
      countries: countries,

      pickup: "",
      dropoff: "",
      options: {
        color: "#ffc04c",
        width: 490,
        zIndex: 200,
      },

      deleteId: "",
      countrySortName: [],
      source: "",
      select: [],
      items: [],
      res: "",
      indexid: "",
      checklistField: [],
      staticlistField: [],
      workingCountries: [],

      dialog: false,
      dialog2: false,
      message: null,
      loading: false,
      processing: false,
      sourceId: "",
      x: {
        error: "",
        message: "",
        success: false,
      },
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Please fill valid  value",
      },
    };
  },
  components: {
    ErrorBox,
    SuccessDialog,
  },
  methods: {
    cancel() {
      this.dialog2 = false;
    },
    OpenConfirmPop() {
      if (this.$refs.form.validate()) {
        this.dialog2 = true;
      } else {
        this.x.error = "Please fill all  required Field";
        this.dialog2 = false;
      }
    },
    close() {
      this.dialog = false;
    },
    initLoadingAutocomplete(n) {
      // console.log(n);
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1")
      );
      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        // console.log(place);
        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var x = [];
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.dropoff = x[1];
          // this.updateInfo.sourceCity = x[0];
        }
      });

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.

      // console.log(place.formatted_address);
    },
    deleteRequest() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;

      let obj = [];
      obj.push(`_id=${this.deleteId}`);
      obj.push(`sourceId=${this.sourceId}`);

      let Url = "/dmsAdmin/delete-additional-charge-name?";
      let params = obj.join("&");
      Url = Url + params;

      this.axios.get(this.constants.apiUrl + Url).then((response) => {
        this.deleteId = "";
        this.removeCheckList(this.indexid);
        this.dialog = false;
      });
    },
    async checkAdd() {
      // this.operationName = "add-milestones";
      // let y = await this.checkOpertaionPermission(this.operationName);
      // if (y) {
      this.addAdditionalCharge();
      // } else {
      //   return;
      // }
    },
    closeMessage() {
      this.pickup = "";
      this.dropoff = "";

      this.x.success = false;
    },
    fetchAdditionalChargeList() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      this.axios.defaults.headers.common["authorization"] = `bearer ${token}`;
      let obj = [];
      obj.push(`sourceId=${this.$route.params.sourceId}`);

      // obj.push(`destination=${this.$route.params.country}`);

      let Url = "/dmsAdmin/additional-charge-name?";
      let params = obj.join("&");
      Url = Url + params;

      this.axios.get(this.constants.apiUrl + Url).then((response) => {
        // console.log(response.data.data);

        let data;
        data = response.data.data[0];
        this.countrySortName = data.country;
        this.workingCountries.push(data.country);
        this.sourceId = data.sourceId;
        this.source = data.source;
        this.dropoff = data.destination;
        //   this.editAdditionalName = data.name;
        // data.map((e) => e.name);

        if (data.list.length) {
          let invoiceList = [];

          invoiceList = data.list.map((it, index) => {
            return {
              i: index,
              name: it.name,
              _id: it._id,
              status: it.status,
            };
          });

          this.staticlistField = data.list.map((it, index) => {
            return {
              i: index,
              name: it.name,
              _id: it._id,
              status: it.status,
            };
          });

          this.checklistField = [];
          this.checklistField.push(...invoiceList);
          // console.log(this.checklistField);
        }
      });
    },

    addAdditionalCharge() {
      // console.log(this.select);
      // console.log(this.countrySortName.toString());
      if (!navigator.onLine) {
        this.x.error = "Please check your internet connection";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        return;
      }
      if (this.processing == true) {
        return;
      } else {
        // console.log(this.checklistField);
        if (this.$refs.form.validate()) {
          // console.log("delete");
          if (
            !this.dropoff.trim() ||
            !this.countrySortName.length ||
            !this.source.trim()
          ) {
            this.x.error = "Please fill all Fields";
            this.processing = false;
            this.dialog2 = false;
          } else {
            if (this.checklistField.some((e) => e.name == null) === true) {
              this.x.error = "Please fill all  additional charge name Field";
              this.dialog2 = false;
              this.processing = false;
            } else {
              this.processing = true;
              let url = editAdditionalCharge;
              delete this.axios.defaults.headers.common["token"];
              let token = localStorage.getItem(StorageKeys.SessionKey);
              let config = {
                headers: {
                  authorization: `bearer ${token}`,
                },
              };
              // console.log(this.checklistField);
              // console.log(this.staticlistField);
              this.staticlistField;

              this.checklistField.forEach((e) => {
                this.staticlistField.forEach((p) => {
                  // console.log(e, "---eChecklist");
                  // console.log(p, "----pstatic");
                  if (e._id) {
                    if (e._id === p._id) {
                      // console.log(p.name, e.name);
                      if (p.name === e.name) {
                        // console.log("-match name", e.i);
                        return;
                      } else {
                        // console.log("-match not", e.i);

                        e.status = "PENDING";
                      }
                    }
                  }
                });
              });
              // console.log(this.checklistField);

              let checkList = [];
              this.checklistField.forEach((element) => {
                delete element["i"];
                checkList.push(element);
              });
              let body = {
                destination: this.dropoff.trim(),
                data: checkList,
                country: this.countrySortName.toString(),
                source: this.source,
                sourceId: this.sourceId,
              };
              this.axios.put(this.constants.apiUrl + url, body, config).then(
                (response) => {
                  this.x.success = true;
                  this.staticlistField = [];
                  this.checklistField = [];
                  this.x.message = response.data.message;
                  this.$router.push("/additionalCharge");
                  this.processing = false;
                },
                (error) => {
                  this.dialog2 = false;
                  this.processing = false;
                  this.x.error = error.response.data.message;
                }
              );
            }
          }
        } else {
          this.x.error = "Please fill all  additional charge name Field";
          this.dialog = false;
          this.processing = false;
        }
      }
    },
    addChecklist() {
      let el = this.checklistField.length;
      let checklist = {
        i: el - 1 + 1,
        name: null,
      };
      this.checklistField.push(checklist);
    },

    openDeletePopup(id, obj) {
      if (this.checklistField.length == 1) {
        this.x.error = "At Least One Value need to be Present";
        return;
      }
      if (obj.name && obj._id) {
        this.deleteId = obj._id;
        this.indexid = id;

        this.dialog = true;
        this.message = `Do you want to delete this ${obj.name} Additional Charge  from list?`;
      } else {
        this.removeCheckList(id);
      }
    },

    removeCheckList(i) {
      if (this.checklistField.length == 1) {
        this.x.error = "At Least One Value need to be Present";
      } else {
        let el = this.checklistField;
        el.splice(i, 1);
        this.checklistField = el;
        this.checklistField.map((e, index) => (e.i = index));
      }
    },
    goback() {
      this.$router.go(-1);
    },
  },
  computed: {
    // workingCountries: function () {
    //   if (localStorage.getItem("workingCountries") !== null) {
    //     return JSON.parse(localStorage.workingCountries).map((m) => ({
    //       text: m.value,
    //       value: m.value,
    //     }));
    //   }
    //   return [];
    // },
  },
};
</script>
<style scoped>
.milestone {
  background: #ffffff;
}
.title-layout-size {
  padding: 3px 16px !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: block;
}
.addMilestone {
  margin: 0 auto;
}
.add-icon {
  border: 2px solid darkorange;
  border-radius: 50%;
  margin: 25px 10px 20px 0px;
  float: left;
  cursor: pointer;
}
.text-field-check:not(:first-child) {
  float: right;
  width: 90%;
}
.headline {
  font-size: 12px;
}
</style>
