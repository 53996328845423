<template>
  <v-dialog v-model="showDialog" persistent max-width="500">
    <v-card>
      <v-card-title
        v-if="heading"
        style="background-color: #f5f5f5; padding-bottom: 6px; padding-top: 6px"
      >
        <span class="headline2">{{ heading }} </span></v-card-title
      >
      <v-card-text>
        <div class="heading py-3 px-3">
          {{ content || "Successful" }}
          <v-icon color="orange" small>check_circle</v-icon>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat @click.native="closeDialog">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    content: String,
    heading: String,
    show: {
      type: Boolean,
      default: false,
    },
    onclose: Function,
  },
  data() {
    return {
      showDialog: this.show,
    };
  },
  watch: {
    show(val) {
      this.showDialog = val;
    },
  },
  methods: {
    closeDialog() {
      this.onclose();
    },
  },
};
</script>

<style>
.v-card__title span.headline2 {
  font-size: 20px !important;
}
</style>
